import React from "react"
import {Button, Carousel} from 'react-bootstrap'
import JsonData from '../data/data.json'
  
export const CarouselComponentForOffer = (props) =>{
    return (props.trigger)?
 (
  <div className="popup">
    <div className="popup-inner">
    <img className="close-btn closeButton" onClick={()=>props.closePopup(false)} src="/img/offers/closeButton.png"/>
  <Carousel variant="dark">
     
      {JsonData.offers?JsonData.offers.map((temp,index) =>(
  temp.imgUrl.map((d,i) => {
    return (
      <Carousel.Item key={i}>
        <img
          className="d-block w-100 offerImage"
          src={d}
          alt="First slide" 
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
    );
  })
       
                  )):"Loading...."
        }
    </Carousel> 
    
  </div>
  </div> ):"";
}