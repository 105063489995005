import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useParams } from 'react-router-dom';
export const Navigation = (props) => {
  const {productId} = useParams();
  return (
    <Navbar id='menu' bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href='/'><img src='/img/logo/devargologo1.png' width="100vh" alt="logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
         {productId?<Nav><Nav.Link href="/">Go To Home</Nav.Link></Nav>:<Nav >
            <Nav.Link href="#product" className='page-scroll'>PRODUCTS</Nav.Link>
            {/* <Nav.Link href="#recipes" className='page-scroll'>RECIPES</Nav.Link> */}
            <Nav.Link href="#about" className='page-scroll'>ABOUT US</Nav.Link>
            <Nav.Link href="#contact" className='page-scroll'>CONTACT US</Nav.Link>
          </Nav>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
