import { About } from "./about"
import { Contact } from "./contact"
import { Footer } from "./footer"
import { Header } from "./header"
import { Navigation } from "./navigation"
import { Products } from "./products"
import JsonData from '../data/data.json'
import { useState } from "react"
import { useEffect } from "react"
import { CarouselComponentForOffer } from "./carouselComponentForOffer"

export const HomePage = () => {
    const [landingPageData, setLandingPageData] = useState({})
    const [isOfferPopup,setOfferPopup] = useState(false)
  useEffect(() => {
    setLandingPageData(JsonData)
    setTimeout(()=>{
      setOfferPopup(true);
    },3000)
  }, [])
    return <>
    <Navigation />
     <CarouselComponentForOffer trigger={isOfferPopup} closePopup = {setOfferPopup}/>
      <Header data={landingPageData.Header} />
      <Products data={landingPageData.Products} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact}/>
      <Footer />
    </>
}