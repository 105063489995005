import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import {Products } from './components/products'
import { About } from './components/about'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import { Testimonials } from './components/testimonials'
import { Footer } from './components/footer'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { Contact } from './components/contact'
import {BrowserRouter , Routes , Route} from 'react-router-dom'
import { HomePage } from './components/homePage'
import ProductDetails from './components/productDetails'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element = {<HomePage/>}/>
        <Route exact path="/productDetails/:productId" element={<ProductDetails/>}/>
        </Routes>
      </BrowserRouter>
      
    </div>
  )
}

export default App
