import { useParams } from "react-router-dom";
import Card from "./Card"

export const Products = (props) => {
  const {productId} = useParams();
  return (<>
  {/* <div class="bee">
   
  </div>
  <div className="leftLine"></div>
    <div className="rightLine"></div>
    <div className="beeNotification">
    
      <h1>Notification</h1>
    </div> */}
    <div className="my-5 one" id="product">
               {productId?<h4>Related Products</h4>:<h4 className="text-center">Our Services</h4>}
            </div>
            <div className="container mb-5">
            <div className="row">
                <div className="col-10 mx-auto">
                    <div className="row gy-4">
          {props.data
            ? props.data.map((d, i) => (
                <Card id={d.id} imgsrc={d.imgUrl} title={d.title} text={d.text} key={i}/>
              ))
            : 'Loading...'}
        </div> 
      </div>
    </div>
    </div>
    </>
  )
}
