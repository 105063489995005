import React from "react"
import {Carousel} from 'react-bootstrap'
import JsonData from '../data/data.json'
  
export const CarouselComponent = (props) =>{
  console.log(props)
    return (
 <>
    <Carousel variant="dark">
     
      {props.data?props.data.imgUrl.map((temp,index) =>(
        
      <Carousel.Item key={index}>
        <img
          className="d-block w-100"
          src={temp}
          alt="First slide" 
        />
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
          )):"Loading...."}
    </Carousel>
  </>)
   
}