import React from 'react';
import Button from 'react-bootstrap/Button';
import Card1 from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';

const Card = ({ 
    id,imgsrc,
    title,text
}) => {
    let navigate = useNavigate();

    function changeLocation(placeToGo){
        navigate(placeToGo, { replace: true });
        window.location.reload();
    }
   
    return (
        <>
            <div className="col-sm-12 col-md-4">
                <div className="card">
                    <img src={imgsrc} className="card-img-top" id="coverImg" alt="card" />
                    
                    <div className="card-body">
                        <h4 className="card-title">{title}</h4>
                        <p className="card-text">{text}</p> 
                       
                    </div>
                    <Link to={`/productDetails/${id}`} onClick={() => changeLocation(`/productDetails/${id}`)}>  <Button variant="success" >Show More</Button></Link>
                
                     </div>
            </div>  
            {/* <Card1 style={{ width: '18rem' }}>
      <Card1.Img variant="top" src={imgsrc}/>
      <Card1.Body>
        <Card1.Title>
        <h4>{title}</h4>
        </Card1.Title>
        <Card1.Text>
          <p>{text}</p>
        </Card1.Text>
        <Link to={`/productDetails/${id}`} onClick={() => changeLocation(`/productDetails/${id}`)}>  <Button variant="success" >Show More</Button></Link>
               
      </Card1.Body>
    </Card1> */}
        </>
    )
}

export default Card;
