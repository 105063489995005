import React from "react";
import {Link , useParams} from 'react-router-dom'
import { CarouselComponent } from "./carouselComponent";
import { Navigation } from "./navigation";
import { useState } from "react";
import { useEffect } from "react";
import JsonData from '../data/data.json'
import { Products } from "./products";
import { Contact } from "./contact";
import { Footer } from "./footer";

const ProductDetails = (props) =>{
    let [landingPageData, setLandingPageData] = useState({})
    let [specificProductDetails,setSpecificProductDetails] = useState();
    let [relatedProducts,setRelatedProducts] =useState([]);
    const {productId} = useParams();
    let temp =[];
    useEffect(() => {
      setLandingPageData(JsonData)
      console.log(landingPageData)
      for(let i=0;i<JsonData.ProductDetails.length;i++){
        console.log(JsonData.ProductDetails[i].id +" "+productId)
        if(JsonData.ProductDetails[i].id == productId){
            setSpecificProductDetails(JsonData.ProductDetails[i])
        }
    }
    for(let i=0;i<JsonData.Products.length;i++){
        if(JsonData.Products[i].id!= productId){
            console.log("gh"+JsonData.Products[i].id)
            temp.push(JsonData.Products[i]);
        }
    }
    setRelatedProducts(temp);
    }, [])
   console.log(relatedProducts.length)
    //let title = specificProductDetails?specificProductDetails.title:"Loading";
    //let description =specificProductDetails?specificProductDetails.description:"Loading";
    
    return <>
    <Navigation/>
    <div className="container">
        <div className="row carouselCss">
           
            <div className="col-xl-4 col-md-4 col-sm-12">
            <CarouselComponent data={specificProductDetails}/>
            </div>
            <div className="col-xl-8 col-md-8 col-sm-12 ">
            <div className="row mt-1 productDetails1">
                <div className="row text-center two">
                    <h4>{specificProductDetails?specificProductDetails.title:"Loading"}</h4>
                    <p>{specificProductDetails?specificProductDetails.description:"Loading"}</p>
                </div>
             <div className="row beeGifDiv">
                    <div className="row">
                    <div className="col-12 two">
                {specificProductDetails?specificProductDetails.specifications.map(data =>{
                    return <><p> <i className={data.icon}></i>  {data.text}</p></>
                }):"loading.."}
                    </div>
                    </div>
             </div>
        </div>
            </div>
        </div>
        {/* <div className="row mt-1 productDetails1">
          <div className="row text-center two">
               <h4>{specificProductDetails?specificProductDetails.title:"Loading"}</h4>
               <p>{specificProductDetails?specificProductDetails.description:"Loading"}</p>
          </div>
          <div className="row beeGifDiv">
            <div className="row">
            <div className="col-12 two">
           {specificProductDetails?specificProductDetails.specifications.map(data =>{
            return <><p> <i className={data.icon}></i>  {data.text}</p></>
           }):"loading.."}
            </div>
            </div>
          </div>
        </div> */}
        <hr/>
        <div className="row mt-1">
           <div className="row text-center mt-2 one">
            <h4>Benefits of {specificProductDetails?specificProductDetails.title:"Loading"}</h4>
           </div>
           <div className="row mt-3">
           <div className="col-xl-2 col-md-0 col-xs-0 benefitrow">
            </div>
            <div className="col-xl-8 col-md-12 col-xs-12 benefitrow">
            <img src={specificProductDetails?specificProductDetails.benefits:"Loading"}/>
            </div>
            <div className="col-xl-2 col-md-0 col-xs-0 benefitrow">
            </div>
           </div>
        </div>
        <hr/>
        <div className="row mt-3">
           <div className="row text-center one">
            <h4>Why Choose us?</h4>
            
           </div>
           <div className="row mt-3">
           <div className="col-xl-2 col-md-0 col-xs-0 benefitrow">
            </div>
            <div className="col-xl-8 col-md-12 col-xs-12 benefitrow">
            <img src={specificProductDetails?specificProductDetails.standOut:"Loading"}/>
            </div>
            <div className="col-xl-2 col-md-0 col-xs-0 benefitrow">
            </div>
           </div>
        </div>
        {/* <hr/>
        <div className="row">
        <div className="row text-center">
           <Products data={relatedProducts} />
        </div>
        </div> */}
    </div>        
    
    </>
}
export default ProductDetails;