import ParticlesBg from "particles-bg";

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
        
          <div className='container'>
          
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-xl-12  intro-text hero'>
                <img src="img/products/allImages_new_2.png" className='img-thumbnail' />
              </div>
              {/* <div className="col-md-12 col-xl-6 intro-text">
                  Product Images
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
